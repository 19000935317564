:root {
  --ion-color-primary: #369be5;
  --ion-color-primary-rgb: #369be5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: #000000;
  --ion-color-primary-shade: #68b4eb;
  --ion-color-primary-tint: #4aa5e8;

  --ion-color-secondary: #369be5;
  --ion-color-secondary-rgb: #3dc2ff;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: #ffffff;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: #5260ff;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: #fff;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #14b8a6;
  --ion-color-success-rgb: #14b8a6;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: #fff;
  --ion-color-success-shade: #14b8a6;
  --ion-color-success-tint: #14b8a6;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: #000000;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: #fff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: #222428;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: #fff;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: #92949c;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: #ffffff;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: #f4f5f8;
  --ion-color-light-contrast: #369be5;
  --ion-color-light-contrast-rgb: #000;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-background-deep-color: #fff;

  --ion-color-step-50: #fff;
}

:root .supervising-resource {
  --ion-color-primary: #000;
  --ion-color-primary-rgb: #369be5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: #000;
  --ion-color-primary-shade: #cccccc;
  --ion-color-primary-tint: #4aa5e8;

  --ion-color-secondary: #153d59;
  --ion-color-secondary-rgb: #3dc2ff;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: #fff;
  --ion-color-secondary-shade: #1c537a;
  --ion-color-secondary-tint: #1b4f74;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: #5260ff;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: #fff;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #14b8a6;
  --ion-color-success-rgb: #2dd36f;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: #fff;
  --ion-color-success-shade: #16d6c0;
  --ion-color-success-tint: #18d4be;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: #000;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: #fff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: #222428;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: #fff;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: #92949c;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: #fff;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: #f4f5f8;
  --ion-color-light-contrast: #369be5;
  --ion-color-light-contrast-rgb: #000;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-background-deep-color: #fff;
}

:root .venue-manager {
  --ion-color-primary: #153d59;
  --ion-color-primary-rgb: #369be5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: #000;
  --ion-color-primary-shade: #cccccc;
  --ion-color-primary-tint: #4aa5e8;

  --ion-color-secondary: #153d59;
  --ion-color-secondary-rgb: #3dc2ff;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: #fff;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: #fff;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: #2dd36f;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: #fff;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: #000;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: #fff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: #222428;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: #fff;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: #92949c;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: #fff;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: #f4f5f8;
  --ion-color-light-contrast: #369be5;
  --ion-color-light-contrast-rgb: #000;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-background-deep-color: #fff;
}

:root .dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: #428cff;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: #fff;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;
  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: #50c8ff;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: #fff;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;
  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: #6a64ff;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: #fff;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;
  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: #2fdf75;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: #000;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;
  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: #ffd534;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: #000;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: #ff4961;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: #fff;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: #f4f5f8;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: #000;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: #989aa2;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: #000;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #222428;
  --ion-color-light-rgb: #222428;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: #fff;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
  --ion-color-background-deep-color: #121212;
  --ion-background-color: #121212;
  --ion-background-color-rgb: #121212;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: #fff;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-item-background: #1a1b1e;
}
