/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

html body {
  display: inherit !important;
}

.part-screen {
  .modal-wrapper {
    width: 300px;
    height: 25%;
    margin: 0 auto;
    border-radius: 10px;
  }
}

.intercom-lightweight-app-launcher {
  bottom: 65px !important;
}

.intercom-launcher-frame {
  bottom: 65px !important;
}

.error-text {
  margin-left: 5px;
}

.circle-icon {
  font-size: 23px;
  background-color: var(
    --ion-color-primary
  ); /* Adjust the color to the desired shade of blue */
  border-radius: 50%; /* Makes the div circular */
  width: 40px; /* Size of the circle */
  height: 40px; /* Size of the circle */
  display: flex;
  justify-content: center; /* Horizontally centers the icon inside the circle */
  align-items: center; /* Vertically centers the icon inside the circle */
  color: var(--ion-color-primary-contrast); /* Icon color */
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
